import React from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import { renderToStaticMarkup } from "react-dom/server"
import parse, { domToReact } from "html-react-parser"
import Figure from "src/components/Figure"
import TwitterEmbed from "src/components/TwitterEmbed"
import Accordion from "src/components/Accordion"
import ImageSlider from "src/components/ImageSlider"
import ImageGallery from "src/components/ImageGallery"
import Subscriptions from "src/components/Subscriptions"
import {
  SecondaryTitle,
  Paragraph,
  List,
  Verse,
} from "src/components/Typography"

export default function parseContent(content, title, path) {
  const options = {
    replace: (node) => {
        const { type, name, attribs, children } = node
      // Paragraph
      if (name === "p") {
        const hasOneImage =
          children.filter(child => child.name === "img").length === 1
        if (hasOneImage) {
          const image = children.find(child => child.name === "img")
          let caption = ""
          function getCaption(children) {
            children.map(child => {
              if (child.type === "text") {
                caption += child.data
              } else if (child.name === "i") {
                getCaption(child.children)
              }
            })
          }
          getCaption(children)
          return (
            image && (
              <Figure
                imageSrc={image.attribs.src}
                imageSrcSet={image.attribs.srcset}
                imageSizes={image.attribs.sizes}
                imageAlt={image.attribs.alt}
                caption={caption}
              />
            )
          )
        } else {
          return <Paragraph className={attribs.class}>{domToReact(children, options)}</Paragraph>
        }

        // SecondaryTitle
      } else if (["h2", "h3", "h4"].includes(name)) {
        return <SecondaryTitle>{domToReact(children, options)}</SecondaryTitle>

        // iframe
      } else if (
        name === "iframe"
      ) {
        return (
          <IframeWrapper>{domToReact([node])}</IframeWrapper>
        )

        // List
      } else if (name === "ul" && !attribs.class) {
        const items = []
        children
          .filter(child => child.name === "li")
          .forEach(child => {
            if (child.children.find(child => child.type === "text")) {
              items.push(
                child.children.find(child => child.type === "text").data
              )
            }
          })
        return <List items={items} />

        // Verse
      } else if (
        name === "pre" &&
        attribs.class &&
        attribs.class.includes("wp-block-verse")
      ) {
        return <Verse>{domToReact(children, options)}</Verse>

        // Figure
      } else if (
        name === "figure" &&
        attribs.class &&
        attribs.class.includes("wp-block-image")
      ) {
        const image = children.find(child => child.name === "img")
        const captionEl = children.find(child => child.name === "figcaption")
        const caption =
          captionEl &&
          captionEl.children &&
          renderToStaticMarkup(domToReact(captionEl.children))
        return (
          image && (
            <Figure
              imageSrc={image.attribs.src}
              imageSrcSet={image.attribs.srcset}
              imageSizes={image.attribs.sizes}
              imageAlt={image.attribs.alt}
              caption={caption}
            />
          )
        )

        // TwitterEmbed
      } else if (
        name === "figure" &&
        attribs.class &&
        attribs.class.includes("wp-block-embed-twitter")
      ) {
        const url = children[0].children
          .find(child => child.name === "blockquote")
          .children.find(child => child.name === "a").attribs.href
        const id = url
          .split("?")[0]
          .split("/")
          .slice(-1)
        if (parseInt(id)) {
          return <TwitterEmbed tweetId={id} />
        }

        // Accordion
      } else if (
        name === "div" &&
        attribs.class &&
        attribs.class.includes("air-accordion-block")
      ) {
        const accordionItems = []
        const accTitles = []
        const accContents = []
        children.map(child => {
          if (child.name === "h5") {
            accTitles.push(child.children[0].data)
          }
          if (
            child.name === "div" &&
            child.attribs.class &&
            child.attribs.class.includes("air-accordion-block__content")
          ) {
            const accContentsArr = []
            child.children.map(accChild => {
              if (accChild.name === "p") {
                accContentsArr.push(
                  renderToStaticMarkup(domToReact(accChild.children))
                )
              }
            })
            accContents.push(accContentsArr)
          }
        })
        accTitles.forEach((title, index) => {
          accordionItems.push({
            title,
            content: accContents[index],
          })
        })
        return <Accordion items={accordionItems} />

        // Logos
      } else if (
        name === "div" &&
        attribs.class &&
        attribs.class === "air-logo-showcase-block"
      ) {
        return (
          <Logos>
            {children
              .filter(child => child.name === "div")
              .map((child, index) => {
                const a = child.children.find(child => child.name === "a")
                const img = child.children.find(child => child.name === "img")
                const aimg = a
                  ? a.children.find(child => child.name === "img")
                  : null
                const logo =
                  a && aimg ? (
                    <a href={a.attribs.href} target="_blank">
                      <Logo src={aimg.attribs.src} alt="" />
                    </a>
                  ) : (
                    <Logo src={img.attribs.src} alt="" />
                  )
                return <LogoItem key={index}>{logo}</LogoItem>
              })}
          </Logos>
        )

        // Subscriptions Block
      } else if (
        name === "div" &&
        attribs.class &&
        attribs.class.includes("air-subscriptions-block")
      ) {
        const productElements = children.filter(
          child => child.name === "div" && child.attribs["data-product-id"]
        )
        const productIds = productElements.map(
          el => el.attribs["data-product-id"]
        )

        return <Subscriptions productIds={productIds} />
        // ImageSlider and ImageGallery
      } else if (
        name === "figure" &&
        attribs.class &&
        attribs.class.includes("wp-block-gallery")
      ) {
        const items = []
        const titleEl = children.find(child => child.name === "h5")
        const title = titleEl && titleEl.children[0].data
        const ul = children.find(child => child.name === "ul")
        ul.children.map(child => {
          const figure = child.children[0]
          const img = figure.children.find(child => child.name === "img")
          const dataFullSrc = img.attribs["data-full-url"]
          const ext = "." + img.attribs.src.split(".").slice("-1")
          const parsedFullSrc =
            img.attribs.src
              .split("-")
              .slice(0, -1)
              .join("-") + ext
          const fullSrc = dataFullSrc || parsedFullSrc || img.attribs.src
          let thumbnailSrc
          const srcSet = img.attribs.srcset && img.attribs.srcset.split(", ")
          if (srcSet) {
            srcSet.forEach(setItem => {
              const spl = setItem.split(" ")
              const setItemSrc = spl[0]
              const setItemWidth = parseInt(spl[1])
              if (setItemWidth > 300 && setItemWidth <= 768) {
                thumbnailSrc = setItemSrc
              }
            })
          }
          thumbnailSrc = thumbnailSrc || img.attribs.src
          const sizes = thumbnailSrc
            .split(".")
            .slice(-2, -1)[0]
            .split("-")
            .slice(-1)[0]
          const width = parseInt(sizes.split("x")[0])
          const height = parseInt(sizes.split("x")[1])
          const captionEl = figure.children.find(
            child => child.name === "figcaption"
          )
          const caption = captionEl && captionEl.children[0].data
          items.push({
            image: {
              fullSrc,
              thumbnailSrc,
              width,
              height,
            },
            caption,
          })
        })

        if (attribs.class.includes("type-slideshow")) {
          return (
            <ImageSliderContainer>
              <ImageSlider items={items} />
            </ImageSliderContainer>
          )
        } else if (attribs.class.includes("type-gallery")) {
          return (
            <ImageGallery
              title={title}
              items={items}
              articleTitle={title}
              articleUrl={path}
            />
          )
        }
      }
    },
  }

  return parse(content, options)
}

const ImageSliderContainer = styled.div`
  margin-left: -${p => p.theme.sidePadding.phone}px;
  margin-right: -${p => p.theme.sidePadding.phone}px;

  ${media.tablet} {
    margin-left: -${p => p.theme.sidePadding.tablet}px;
    margin-right: -285px;
  }

  ${media.desktop} {
    margin-left: -${p => p.theme.sidePadding.desktop}px;
    margin-right: -405px;
  }

  ${media.desktopMedium} {
    margin-left: -25.4098361%;
    margin-right: -76.2295082%;
  }
`

const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 95px;
  margin-bottom: 160px;
`

const LogoItem = styled.div`
  width: calc(100% / 3);
  text-align: center;

  &:nth-child(n + 4) {
    margin-top: 115px;
  }
`

const Logo = styled.img``

const IframeWrapper = styled.div`
  iframe {
    max-width:100%;
  }
`