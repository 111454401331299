import { graphql } from "gatsby"
export default () => {}
export const query = graphql`
  fragment MediaFragment on wordpress__wp_media {
    source_url
    caption
    alt_text
    media_details {
      width
      height
      sizes {
        thumbnail {
          source_url
          width
          height
        }
        medium {
          source_url
          width
          height
        }
        large {
          source_url
          width
          height
        }
        wordpress_1536x1536 {
          source_url
          width
          height
        }
        wordpress_2048x2048 {
          source_url
          width
          height
        }
      }
    }
  }
  fragment ArtistFragment on wordpress__wp_artist {
    title
    path
    content
    excerpt
    artist_category {
      name
    }
    acf {
      artist_power_100 {
        place
        edition {
          wordpress_id
          name
        }
        next {
          path
        }
        prev {
          path
        }
      }
      hero_image {
        ...MediaFragment
      }
    }
    featured_media {
      ...MediaFragment
    }
  }
  fragment ArticleFragment on wordpress__POST {
    id: wordpress_id
    slug
    title
    excerpt
    content
    path
    format
    date(formatString: "DD MMMM YYYY")
    branch {
      name
    }
    author {
      name
      path
    }
    category {
      name
      path
    }
    featured_media {
      ...MediaFragment
    }
    acf {
      article_artist {
        title
        path
      }
      article_video
      article_audio
      article_collaboration
      article_custom_html_snippet
      article_highlight
      article_custom_link_url
      hero_image {
        ...MediaFragment
      }
    }
  }
  fragment StoryTeaserFragment on wordpress__POST {
    id: wordpress_id
    title
    excerpt
    path
    date(formatString: "DD MMMM YYYY")
    format
    branch {
      name
    }
    author {
      name
      path
    }
    category {
      name
    }
    featured_media {
      ...MediaFragment
    }
    acf {
      article_collaboration
      article_video
      article_audio
    }
  }
  fragment ArticlesListFragment on wordpress__POST {
    id: wordpress_id
    title
    path
    author {
      name
      path
    }
    category {
      name
      path
    }
    featured_media {
      ...MediaFragment
    }
    acf {
      article_collaboration
    }
  }
`
