import React, { useState } from "react"
import styled from "styled-components"
import ImageGalleryZoom from "src/components/ImageGalleryZoom"

export default ({ title, articleTitle, articleUrl, items }) => {
  const [activeZoom, setActiveZoom] = useState(null)

  function setIndexes() {
    items.forEach((item, index) => {
      item.realIndex = index
    })
    return items
  }

  function setOrientation() {
    thumbnails.forEach(item => {
      const orientation =
        item.image.width > item.image.height ? "horizontal" : "vertical"
      item.orientation = orientation
    })
  }

  function getWidth(item) {
    return item.orientation === "horizontal" ? 2 : 1
  }

  function findItem(fromIndex, orientation) {
    return (
      thumbnails.slice(fromIndex).length &&
      thumbnails.slice(fromIndex).find(item => item.orientation === orientation)
    )
  }

  function compressGrid() {
    let count = 0
    let width = 0
    let lastChildInRowIndex = 0

    for (const [index, item] of thumbnails.entries()) {
      width = width + getWidth(item)
      count++
      if (width === 3 || width === 4) {
        lastChildInRowIndex = index
        break
      } else if (width >= 5) {
        lastChildInRowIndex = index - 1
        break
      }
    }

    if (width === 3) {
      const itemToFill = findItem(count, "vertical")
      if (itemToFill) {
        thumbnails[lastChildInRowIndex + 1] =
          thumbnails[thumbnails.indexOf(itemToFill)]
      }
    }

    const max = getMaxQuantity()
    let totalWidth = 0
    thumbnails.slice(0, max).forEach((item, index) => {
      totalWidth = totalWidth + getWidth(item)
    })
    if (totalWidth === 7) {
      const lastItemToFill = findItem(max, "vertical")
      if (lastItemToFill) {
        thumbnails[max] = thumbnails[thumbnails.indexOf(lastItemToFill)]
      } else {
        const lastItemToFillAlt = findItem(max, "horizontal")
        if (lastItemToFillAlt) {
          thumbnails[max - 1] =
            thumbnails[thumbnails.indexOf(lastItemToFillAlt)]
        }
      }
    } else if (totalWidth === 6) {
      const lastItemToFillHorz = findItem(max, "horizontal")
      if (lastItemToFillHorz) {
        thumbnails[max] = thumbnails[thumbnails.indexOf(lastItemToFillHorz)]
      }
    }
  }

  function getMaxQuantity() {
    let count = 0
    let volume = 0

    for (let item of thumbnails) {
      count++
      volume = volume + getWidth(item)
      if (volume > 8) {
        count--
        break
      }
    }

    return count
  }

  setIndexes()

  const thumbnails = items.slice(0, 8)

  setOrientation()
  compressGrid()

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Count>{`${items.length} image${items.length > 1 ? "s" : ""}`}</Count>
      <Thumbnails>
        {thumbnails
          .slice(0, getMaxQuantity())
          .map(({ image, orientation, realIndex }, index) => {
            const { thumbnailSrc } = image
            return (
              <Thumbnail
                className={`-orientation-${orientation}`}
                key={index}
                onClick={() => {
                  setActiveZoom(realIndex)
                }}
              >
                <ThumbnailImage src={thumbnailSrc} loading="lazy" />
              </Thumbnail>
            )
          })}
      </Thumbnails>
      {typeof activeZoom === "number" && (
        <ImageGalleryZoom
          title={title}
          items={items}
          activeItemIndex={activeZoom}
          articleTitle={articleTitle}
          articleUrl={articleUrl}
          onClose={() => {
            setActiveZoom(null)
          }}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.figure`
  margin: 45px 0;
`

const Title = styled.h3`
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
`

const Count = styled.p`
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
`

const Thumbnails = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc((100% - 30px) / 4));
  grid-gap: 10px;
`

const Thumbnail = styled.div`
  height: 0;
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    visibility: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border: 2px solid;
    pointer-events: none;
  }

  &:hover {
    &::before {
      visibility: visible;
    }
  }

  &.-orientation-horizontal {
    grid-column: span 2;
    padding-top: 56.6666667%;
  }

  &.-orientation-vertical {
    padding-top: 117.2413793%;
  }
`

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`
