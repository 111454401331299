import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Icon from "src/components/Icon"

export default ({ handle, variants, description, images, onSubscribe }) => {
  const [selectedVariant, setSelectedVariant] = useState(null)

  const onVariantChange = event => {
    setSelectedVariant(
      variants.find(variant => variant.id === event.target.value)
    )
  }

  const onSubmit = event => {
    event.preventDefault()
    onSubscribe(selectedVariant.id)
  }

  useEffect(() => {
    setSelectedVariant(variants[0])
  }, [variants])

  return (
    selectedVariant && (
      <Subscription onSubmit={onSubmit}>
        <SubscriptionColumn>
          {images.length ? (
            <SubscriptionImage src={images[0].src} alt="ArtReview" />
          ) : (
            ""
          )}
        </SubscriptionColumn>
        <SubscriptionColumn>
          <SubscriptionPrice>£{selectedVariant.price}</SubscriptionPrice>
          <SubscriptionOptionsWrapper>
            <SubscriptionOptions
              id={handle + "_variant"}
              name={handle + "_variant"}
              onChange={onVariantChange}
            >
              {variants.map(variant => {
                return (
                  <SubscriptionOption value={variant.id} key={variant.id}>
                    {variant.title}
                  </SubscriptionOption>
                )
              })}
            </SubscriptionOptions>
            <SubscriptionOptionsText>
              {selectedVariant.title}
              <SubscriptionOptionsCaret name="chevron-down" />
            </SubscriptionOptionsText>
          </SubscriptionOptionsWrapper>
          <SubscriptionDetails>{description}</SubscriptionDetails>
          <SubscriptionButton type="submit">Subscribe</SubscriptionButton>
        </SubscriptionColumn>
      </Subscription>
    )
  )
}
const Subscription = styled.form`
  &:nth-child(n + 2) {
    margin-top: 60px;
  }

  ${media.tablet} {
    display: flex;

    &:nth-child(n + 2) {
      margin-top: 81px;
    }
  }
`

const SubscriptionColumn = styled.div`
  ${media.tablet} {
    &:first-child {
      min-width: 310px;
    }

    &:last-child {
      padding-top: 90px;
    }
  }
`

const SubscriptionImage = styled.img``

const SubscriptionPrice = styled.h3`
  margin-bottom: 21px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
`

const SubscriptionOptionsWrapper = styled.div`
  position: relative;
`

const SubscriptionOptions = styled.select`
  width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const SubscriptionOption = styled.option``

const SubscriptionOptionsText = styled.span`
  position: absolute;
  top: 4px;
  left: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: transparent;
  pointer-events: none;
`

const SubscriptionOptionsCaret = styled(Icon)`
  width: 10px;
  height: 10px;
  margin-left: 8px;
  color: #000;
`

const SubscriptionDetails = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);

  ${media.tablet} {
    margin-bottom: 36px;
  }
`

const SubscriptionButton = styled.button`
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
`
