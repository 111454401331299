import React from "react"
import styled from "styled-components"
import { TwitterTweetEmbed } from "react-twitter-embed"

export default ({ tweetId }) => {
  return (
    <Wrapper>
      <TwitterTweetEmbed tweetId={String(tweetId)} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 35px 0;
`
