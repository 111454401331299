import React, { useState } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import useMousePosition from "src/hooks/useMousePosition"
import SocialMediaSharing from "src/components/SocialMediaSharing"
import Icon from "src/components/Icon"
import cursorArrowRightSvg from "src/assets/images/cursor-arrow-right.svg"
import cursorArrowRightSvgWhite from "src/assets/images/cursor-arrow-right-white.svg"

export default ({
  title,
  items,
  activeItemIndex,
  articleTitle,
  articleUrl,
  onClose,
}) => {
  const [currentIndex, setCurrentIndex] = useState(activeItemIndex)
  const currentItem = items[currentIndex]
  const { image, caption } = currentItem
  const { fullSrc } = image
  const { x, y } = useMousePosition()
  const [cursorY, setCursorY] = useState(0)
  const [cursorX, setCursorX] = useState(0)
  const [cursorOver, setCursorOver] = useState(false)
  const [cursorTouchingEdges, setCursorTouchingEdges] = useState(true)

  function navigate(direction) {
    let index = currentIndex + (direction === "next" ? 1 : -1)
    if (index < 0) {
      index = items.length - 1
    } else if (index >= items.length) {
      index = 0
    }
    setCurrentIndex(index)
  }

  function handleMouseMove() {
    setCursorY(y)
    setCursorX(x)
  }

  return (
    <Wrapper>
      <TitleBar>
        <TitleWrapper>
          <Title>{title}</Title>
          <Caption dangerouslySetInnerHTML={{ __html: caption }} />
        </TitleWrapper>
        <Count>
          Image {currentIndex + 1} of {items.length}
        </Count>
        <Sharing
          title={articleTitle}
          path={articleUrl}
          direction="horizontal"
        />
      </TitleBar>
      <ImageContainer
        onMouseEnter={() => {
          setCursorOver(true)
        }}
        onMouseLeave={() => {
          setCursorOver(false)
        }}
        onMouseMove={handleMouseMove}
      >
        <Image src={fullSrc} alt={caption} />
        <NavigationButton
          onClick={() => {
            navigate("prev")
          }}
        />
        <NavigationButton
          onClick={() => {
            navigate("next")
          }}
        />
      </ImageContainer>
      <CloseButton onClick={onClose}>
        <CloseIcon name="x" />
      </CloseButton>
      <Cursor
        className={
          (cursorOver ? "-visible" : "") +
          (typeof window !== "undefined" && x < window.innerWidth / 2
            ? " -prev"
            : " -next")
        }
        style={{ top: `${cursorY}px`, left: `${cursorX}px` }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background: #fff;
`

const TitleBar = styled.header`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 20px 30px 23px;
  position: absolute;
  bottom: 0;
  left: 0;

  > :first-child,
  > :last-child {
    width: calc((100% - 150px) / 2);
  }
`

const TitleWrapper = styled.div``

const Title = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
`

const Caption = styled.h3`
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
`

const Count = styled.p`
  width: 150px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
`

const Sharing = styled(SocialMediaSharing)`
  display: none;
  text-align: right;

  ${media.tablet} {
    display: flex;
    justify-content: flex-end;
  }
`

const ImageContainer = styled.figure`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 90px;
  left: 0;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const NavigationButton = styled.button`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;

  &:nth-of-type(1) {
    left: 0;
    cursor: none;
  }

  &:nth-of-type(2) {
    left: 50%;
    cursor: none;
  }
`

const CloseButton = styled.button`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 15px;
  background: #fff;
`

const CloseIcon = styled(Icon)`
  width: 18px;
  height: 18px;
`

const Cursor = styled.div`
  width: 95px;
  height: 62px;
  margin-top: -31px;
  margin-left: -47px;
  position: fixed;
  top: 50%;
  left: 50%;
  background: url(${cursorArrowRightSvg}) no-repeat;
  pointer-events: none;

  &.-prev {
    transform: rotate(-180deg);
  }

  &:not(.-touching-edges) {
    background-image: url(${cursorArrowRightSvgWhite});
    mix-blend-mode: difference;
  }

  &:not(.-visible) {
    visibility: hidden;
  }

  ${media.touch} {
    display: none;
  }
`
