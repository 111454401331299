import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"
import Icon from "src/components/Icon"

export default ({
  name,
  value,
  title,
  options,
  hasSearch,
  searchPlaceholder,
  hideAll,
  onChange,
  optionFormatter = v => v,
}) => {
  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState("")
  const wrapper = useRef()
  const searchInput = useRef()

  function toggleOpen(open) {
    setOpen(open)
    if (open) {
      if (hasSearch) {
        setTimeout(() => {
          searchInput.current && searchInput.current.focus()
        }, 10)
      }
    } else {
      setSearchText("")
    }
    wrapper.current.parentNode.classList.toggle("-filter-open", open)
  }

  function updateValue(value) {
    onChange({ name, value })
  }

  function handleClickOutside(event) {
    if (!wrapper.current.contains(event.target)) {
      toggleOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [])

  return (
    <Wrapper
      ref={wrapper}
      className={(hasSearch ? " -has-search" : "") + (open ? " -open" : "")}
    >
      <Title
        onClick={() => {
          toggleOpen(!open)
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: value ? optionFormatter(value) : title,
          }}
        />
        <TitleIcon name="chevron-down" />
      </Title>
      <Dropdown>
        {searchPlaceholder && !value && (
          <SearchInput
            ref={searchInput}
            type="text"
            placeholder={searchPlaceholder}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        )}
        {!hideAll && (
          <OptionsLabel
            className={value ? "" : "-active"}
            onClick={() => {
              updateValue("")
              toggleOpen(false)
            }}
          >
            All
          </OptionsLabel>
        )}
        <Options>
          {(options || []).map((option, index) => {
            return (
              <React.Fragment key={index}>
                {option.toLowerCase().includes(searchText.toLowerCase()) && (
                  <Option
                    key={option + index}
                    className={value === option ? "-active" : ""}
                    onClick={() => {
                      updateValue(option)
                      setOpen(false)
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: optionFormatter(option),
                      }}
                    />
                  </Option>
                )}
              </React.Fragment>
            )
          })}
        </Options>
      </Dropdown>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-block;
  z-index: 2;
  user-select: none;

  &:not(:last-child) {
    margin-right: 20px;
  }

  &.-open {
    z-index: 3;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    .-filter-open > &:not(.-open) {
      display: none;
    }
  }

  ${media.tablet} {
    position: relative;

    &:not(:last-child) {
      margin-right: 40px;
    }
  }
`

const Title = styled.p`
  position: relative;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;

  ${Wrapper}.-open & {
    z-index: 3;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    ${Wrapper}.-open & {
      width: calc(100vw - 40px);
    }
  }

  ${media.tablet} {
    &::before {
      content: "";
      position: absolute;
      top: -10px;
      right: -20px;
      bottom: -10px;
      left: -20px;

      ${Wrapper}.-open & {
        width: 197px;
        right: auto;

        div[class*="FilterBarGroup"]:last-child:not(:first-child) & {
          left: auto;
          right: -20px;
        }
      }
    }
  }
`

const TitleIcon = styled(Icon)`
  width: 10px;
  height: 10px;
  margin-left: 8px;

  ${Wrapper}.-open & {
    transform: rotate(-180deg);
  }
`

const Dropdown = styled.div`
  visibility: hidden;
  min-width: calc(100vw - 4px);
  padding-top: 50px;
  position: absolute;
  top: -16px;
  left: -18px;
  z-index: 2;
  background: #fff;
  border: 2px solid;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);

  ${Wrapper}.-open & {
    visibility: visible;
  }

  ${media.tablet} {
    min-width: 200px;
    left: -20px;

    div[class*="FilterBarGroup"]:last-child:not(:first-child) & {
      left: auto;
      right: -20px;
    }

    ${Wrapper}.-has-search & {
      min-width: 350px;
    }
  }
`

const SearchInput = styled.input`
  display: none;
  width: 200px;
  position: absolute;
  top: 16px;
  right: 18px;
  font-weight: 600;
  color: #000;
  text-align: right;

  &::placeholder {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.4);
  }

  ${media.tablet} {
    display: block;

    div[class*="FilterBarGroup"]:last-child:not(:first-child) & {
      left: 18px;
      right: auto;
      text-align: left;
    }
  }
`

const Options = styled.div`
  max-height: 270px;
  overflow: auto;
  padding-bottom: 12px;
`

const OptionsLabel = styled.p`
  margin-top: 14px;
  margin-left: 18px;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  &:hover,
  &.-active {
    font-weight: 600;
    color: #000;
  }
`

const Option = styled.div`
  padding-left: 28px;
  padding-right: 18px;
  cursor: pointer;
  /* white-space: nowrap; */
  text-indent: -10px;

  &:hover,
  &.-active {
    font-weight: 600;
    color: #000;
    letter-spacing: -0.01em;
  }
`
