import React from "react"
import styled from "styled-components"
import Icon from "src/components/Icon"
import { Paragraph } from "src/components/Typography"

export default ({ items }) => {
  return (
    <Wrapper>
      {items.map(({ title, content }, index) => (
        <Item key={index} open={index === 0}>
          <Title>
            {title} <TitleIcon name="chevron-down" />
          </Title>
          <Content>
            {content.map((paragraph, index) => (
              <Paragraph
                key={index}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            ))}
          </Content>
        </Item>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Item = styled.details`
  padding-left: 30px;
`

const Title = styled.summary`
  margin-bottom: 30px;
  position: relative;
  list-style: none;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  outline: none;
  user-select: none;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }
`

const TitleIcon = styled(Icon)`
  width: 10px;
  height: 10px;
  position: absolute;
  top: 4px;
  left: -30px;

  [open]${Item} & {
    transform: rotate(180deg);
  }
`

const Content = styled.div`
  margin-bottom: 34px;
`
