import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Subscription from "src/components/Subscription"
import Client from "shopify-buy"

export default ({ productIds = [] }) => {
  const [products, setProducts] = useState([])
  const [checkout, setCheckout] = useState(null)
  const [client, setClient] = useState(null)

  const onSubscribe = variantID => {
    if (!checkout || !client) {
      return
    }
    const lineItems = [
      {
        variantId: variantID,
        quantity: 1,
      },
    ]

    client.checkout.replaceLineItems(checkout.id, lineItems).then(checkout => {
      // use redirect instead of new window due to pop-up blockers in Safari and other browsers
      window.location = checkout.webUrl
    })
  }
  useEffect(() => {
    function storeClient(client) {
      setClient(client)
    }

    if (
      process.env.GATSBY_SHOPIFY_DOMAIN &&
      process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN &&
      productIds.length
    ) {
      storeClient(
        Client.buildClient({
          domain: process.env.GATSBY_SHOPIFY_DOMAIN,
          storefrontAccessToken:
            process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        })
      )
    }
  }, [productIds])

  useEffect(() => {
    if (!client) {
      return
    }
    function handleCheckoutChange(checkout) {
      setCheckout(checkout)
    }

    function handleProductsChange(products) {
      const ids = productIds.map(id => btoa(`gid://shopify/Product/${id}`))
      setProducts(
        ids
          .map(id => products.find(product => product.id === id))
          .filter(p => p)
      )
    }

    client.checkout.create().then(handleCheckoutChange)
    client.product.fetchAll(250).then(handleProductsChange)
  }, [client, productIds])

  return (
    <Subscriptions>
      {products &&
        products.map(product => {
          return (
            <Subscription
              onSubscribe={onSubscribe}
              key={product.id}
              handle={product.handle}
              description={product.description}
              images={product.images}
              variants={product.variants}
            />
          )
        })}
    </Subscriptions>
  )
}

const Subscriptions = styled.div`
  margin-top: 70px;

  &:not(:last-child) {
    margin-bottom: 70px;
  }

  ${media.tablet} {
    margin-top: 100px;

    &:not(:last-child) {
      margin-bottom: 90px;
    }
  }
`
