import React, { useState, useRef, useEffect, useCallback } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import useMousePosition from "src/hooks/useMousePosition"
import cursorArrowRightSvg from "src/assets/images/cursor-arrow-right.svg"
import cursorArrowRightSvgWhite from "src/assets/images/cursor-arrow-right-white.svg"

export default ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const currentItem = items[currentIndex]
  const { image, caption } = currentItem
  const imageSrc = image.fullSrc
  const { x, y } = useMousePosition()
  const [cursorY, setCursorY] = useState(0)
  const [cursorX, setCursorX] = useState(0)
  const [cursorOver, setCursorOver] = useState(false)
  const [cursorTouchingEdges, setCursorTouchingEdges] = useState(true)
  const wrapper = useRef()

  function navigate(direction) {
    let index = currentIndex + (direction === "next" ? 1 : -1)
    if (index < 0) {
      index = items.length - 1
    } else if (index >= items.length) {
      index = 0
    }
    setCurrentIndex(index)
  }

  function handleMouseMove() {
    setCursorY(y)
    setCursorX(x)
    const touchingEdges =
      Math.abs(window.innerWidth / 2 - x) - wrapper.current.offsetWidth / 2 >
      -47
    setCursorTouchingEdges(touchingEdges)
  }

  const loadImagesIntoMemory = useCallback(items => {
    // Load all slides so user don't have to wait for images when he changes a slide
    items.forEach(item => {
      if (typeof window === "undefined") return
      const image = new window.Image()
      image.src = item.image.fullSrc
    })
  }, [])

  useEffect(() => {
    loadImagesIntoMemory(items)
  }, [])

  return (
    <Wrapper
      ref={wrapper}
      onMouseEnter={() => {
        setCursorOver(true)
      }}
      onMouseLeave={() => {
        setCursorOver(false)
      }}
      onMouseMove={handleMouseMove}
    >
      <ImageWrapper>
        <Image src={imageSrc} alt={caption} />
        <Count>
          {currentIndex + 1} / {items.length}
        </Count>
        <NavigationButton
          onClick={() => {
            navigate("prev")
          }}
        />
        <NavigationButton
          onClick={() => {
            navigate("next")
          }}
        />
      </ImageWrapper>
      {caption && <Caption dangerouslySetInnerHTML={{ __html: caption }} />}
      <Cursor
        className={
          (cursorOver ? "-visible" : "") +
          (cursorTouchingEdges ? " -touching-edges" : "") +
          (typeof window !== "undefined" && x < window.innerWidth / 2
            ? " -prev"
            : " -next")
        }
        style={{ top: `${cursorY}px`, left: `${cursorX}px` }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.figure`
  margin: 45px 0;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
`

const ImageWrapper = styled.div`
  height: 62.6vw;
  background: #f5f5f5;
  position: relative;

  ${media.desktopMedium} {
    height: 770px;
  }
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Caption = styled.figcaption`
  margin: 6px 80px 0;
  text-align: center;
`

const Count = styled.p`
  position: absolute;
  bottom: -26px;
  left: ${p => p.theme.sidePadding.phone}px;

  ${media.tablet} {
    left: ${p => p.theme.sidePadding.tablet}px;
  }

  ${media.desktopMedium} {
    left: 0;
  }
`

const NavigationButton = styled.button`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;

  &:nth-of-type(1) {
    left: 0;
    cursor: none;
  }

  &:nth-of-type(2) {
    left: 50%;
    cursor: none;
  }
`

const Cursor = styled.div`
  width: 95px;
  height: 62px;
  margin-top: -31px;
  margin-left: -47px;
  position: fixed;
  top: 50%;
  left: 50%;
  background: url(${cursorArrowRightSvg}) no-repeat;
  pointer-events: none;

  &.-prev {
    transform: rotate(-180deg);
  }

  &:not(.-touching-edges) {
    background-image: url(${cursorArrowRightSvgWhite});
    mix-blend-mode: difference;
  }

  &:not(.-visible) {
    visibility: hidden;
  }

  ${media.touch} {
    display: none;
  }
`
